/**
  * Submenu Toggle
 */

const submenuToggle = () => {
  try {
    let items = document.querySelectorAll('.submenuToggleJs');

    for(let i=0; i < items.length; i++) {
      items[i].addEventListener('click', function(){
        items[i].classList.toggle('active');
      });
    }
  } catch (error) {
    //console.log(error);
  }

  //For Nav
  try {
    let menu = document.querySelectorAll('.nav__item--submenu');
    let menu_items = document.querySelectorAll('.nav__item--subitem')
    
    for(let i = 0; i < menu.length; i++) {
      menu[i].addEventListener('click', function() {
        this.classList.toggle('active');

        for(let a = 0; a < menu_items.length; a++) {
          menu_items[a].classList.toggle('active');
        }
      });
    }
  } catch (error) {
    //console.log(error);
  }

  //For hamburger
  try {
    let nav = document.querySelector('.nav');
    let hamburger = nav.querySelector('.nav__hamburger');
    let nav_list = nav.querySelector('.nav__list');

    hamburger.addEventListener('click', function() {
      nav.classList.toggle('active')
      hamburger.classList.toggle('active');
      if(hamburger.classList.contains('active') == true)
        hamburger.querySelector('.nav__hamburger-text').innerHTML = 'close';
      else
      hamburger.querySelector('.nav__hamburger-text').innerHTML = 'MENU';
      nav_list.classList.toggle('active');
    });
  } catch (error) {
    //console.log(error);
  }
}

export default submenuToggle