const fixedNavPc = () => {
  if(screen.width >=769 ) {
    var navbar = document.querySelector(".nav");

    // Get the offset position of the navbar
    var sticky = navbar.offsetTop;
    
    window.addEventListener('scroll', function(){
      if (window.pageYOffset > 500) {
        navbar.classList.add("nav--sticky")
      } else {
        navbar.classList.remove("nav--sticky");
      }
    });
  }
  else {
    document.querySelector(".nav").classList.remove("nav--lower");
  }
}

export default fixedNavPc
