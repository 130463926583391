import submenuToggle from './components/submenuToggle'
import fixedNavPc from './components/fixedNavPc'
import videoplaySP from './components/videoplaySP'


document.addEventListener(
  'DOMContentLoaded',
  () => {
    submenuToggle()
    fixedNavPc()
    videoplaySP()
  },
  false
);

AOS.init({
  once: true
});
