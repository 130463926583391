const videoplaySP = () => {
  let vid = document.querySelector('.header__mainvisual-video ').querySelector('video');
  let mv = document.querySelector('.header__mainvisual');
  vid.play();
  window.addEventListener('load', (event) => {
    vid.play();
    mv.classList.add('header__mainvisual--hidden');
  });
}

export default videoplaySP